import React from 'react'

const Ribbon = ({ children, white }) => {
    return (
        <div className="ribbon" style={white ? { backgroundColor: "white", color: "black" } : { backgroundColor: "black", color: "white" }}>
            <div className="container">
                {children}
            </div>
        </div>
    )
}
export default Ribbon;