import React from "react";
import Panel from "../components/templates/panel";
import Ribbon from "../components/templates/ribbon";
import Purpose from "../components/templates/proposal";
import Content from "../components/templates/content";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Login from "../components/login";

const VisionPage = () => (
    <Layout>
        <Login />
        <SEO title="Our Vision" />
        <Panel imgLink={"/images/visions-1-header.jpg"}>
            <div className="mobile-hide">
                <h1>
                    Here are some of our initial observations about Downtown West’s
                    challenges through the lens of Place Purpose.
        </h1>
            </div>
        </Panel>
        <div className="mobile-show desktop-hide">
            <Ribbon bgColor="black">
                <h1>
                    Here are some of our initial observations about Downtown West’s
                    challenges through the lens of Place Purpose.
        </h1>
            </Ribbon>
        </div>
        <Content>
            <div className="container">
                <div className="breaker-thin"></div>
                <div className="story">
                    <h2>
                        Downtown San Jose has been long waiting to be fully realized.
            <br></br>
                        <br></br> There is a sense of momentum but also of enormous
            challenge.
          </h2>
                </div>
                <br></br>
                <div className="row no gutters">
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-3.jpg" width="100%" />
                    </div>
                    <div className="col-md-8">
                        <img src="/images/content-img-vision-2.jpg" width="100%" />
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        This is a city seeking to tell its story, celebrate its communities
                        and make more opportunities available to all.
            <br></br>
                        <br></br>
            But right now we’re asking ourselves: how do we get back to our
            public places? And when we do, what do we need from them?
          </h2>
                </div>
                <br></br>
                <div className="row no gutters">
                    <div className="col-md-8">
                        <img src="/images/content-img-vision-4.jpg" width="100%" />
                    </div>
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-5.jpg" width="100%" />
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        We’re asking how can places help us reconnect with each other?
            <br></br>
                        <br></br>
            And reconnect us with nature.
          </h2>
                </div>
                <br></br>
                <div className="row no gutters">
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-6.jpg" width="100%" />
                    </div>
                    <div className="col-md-8">
                        <img src="/images/content-img-vision-7.jpg" width="100%" />
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        For Google, this is a new chapter: not a story of “middle age” but
                        of being the trailblazer once again.
          </h2>
                </div>
                <br></br>
                <br></br>
            </div>
            <div className="grey">
                <Ribbon>
                    <br></br>
                    <br></br>
                    <h1 className="compress">
                        So you need to get ahead of the prevailing narrative that you’re
                        building another ‘campus’.
          </h1>
                    <br></br>
                    <br></br>
                </Ribbon>
            </div>
            <div className="container">
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        In this time of great change, there is a deeper understanding that —
                        where we work reflects why we work. And where we live reflects why
                        we live.
            <br></br>
                        <br></br>
            For places, that means people don’t buy what we make — they buy{" "}
                        <u>why we’re making it</u>.
          </h2>
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="ribbonBkGirl">
                <Ribbon white={false}>
                    <h1>
                        Downtown West isn’t about creating a campus. It’s about creating the{" "}
                        <u>stage</u>, so that San Jose can bring the performance. We’ve made
            a concept film to illustrate this idea.
          </h1>
                </Ribbon>
            </div>
            <div className="container">
                <br></br>
                <br></br>
                <video width="100%" height="100%" playsInline controls>
                    <source src="/images/loud-places.mp4" type="video/mp4"></source>
                </video>
                <p className="caption">Rights: for internal use only</p>
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        A stage for San Jose to celebrate San Jose.
            <br></br>
                        <br></br>A stage to put behind-the-scenes work front-and-centre.
            <br></br>
                        <br></br>A stage that introduces people, celebrates talent, creates
            supporters.
            <br></br>
                        <br></br>A stage that uncovers and showcases San Jose stories,
            histories and experiences.
          </h2>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-9.jpg" width="100%" />
                    </div>
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-10.jpg" width="100%" />
                    </div>
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-11.jpg" width="100%" />
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="story">
                    <h2>
                        A stage where Googlers and others can come together to develop and
                        try new ideas.
            <br></br>
                        <br></br>A stage where we learn, fail, succeed.
            <br></br>
                        <br></br>A stage that inspires business communities and industry
            leaders.
            <br></br>
                        <br></br>A stage that is in itself a reason to visit.
          </h2>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-12.jpg" width="100%" />
                    </div>
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-13.jpg" width="100%" />
                    </div>
                    <div className="col-md-4">
                        <img src="/images/content-img-vision-14.jpg" width="100%" />
                    </div>
                </div>
            </div>
        </Content>
        <Purpose
            fwd={true}
            bck={true}
            linkBack={"purpose"}
            titleBack={"Place Purpose"}
            titleTo={"Article"}
            linkTo={"article"}
        />
    </Layout>
);

export default VisionPage;
